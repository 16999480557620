import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/code/src/components/layout.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Rows = makeShortcode("Rows");
const BigBox = makeShortcode("BigBox");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Rows mdxType="Rows">
      <BigBox mdxType="BigBox">
        <p><img parentName="p" {...{
            "src": "/people/claudia-braese-hitabis-berlin.jpg",
            "alt": "Claudia Braese"
          }}></img></p>
        <h3>{`Claudia Braese`}</h3>
        <h4>{`Expertin IT-Dienstleistung, Personal`}</h4>
        <p>{`Claudia Braese ist von Anfang an dabei und Geschäftsführerin der Hitabis GmbH. Sie verfügt seit 1995 über IT-Erfahrungen. Nach ihrer Berufsausbildung zur Industriekauffrau bei der IBM Deutschland GmbH studierte sie Mathematik an der Technischen Fachhochschule Berlin (TFH). Nach ihrem Abschluss als Diplom-Mathematikerin war sie in verschiedenen IT-Unternehmen als Software-Entwicklerin und IT-Beraterin tätig, unter anderem neun Jahre IT-Beraterin bei der Oracle Deutschland GmbH.`}</p>
      </BigBox>
      <BigBox mdxType="BigBox">
        <p><img parentName="p" {...{
            "src": "/people/michael-hagedorn-hitabis-berlin.jpg",
            "alt": "Michael Hagedorn"
          }}></img></p>
        <h3>{`Michael Hagedorn`}</h3>
        <h4>{`Experte Softwareentwicklung`}</h4>
        <p>{`ist ebenfalls einer der Mitgründer und Geschäftsführer der Hitabis GmbH. Nach seiner Facharbeiterausbildung zum Elektromonteur studierte er Theoretische Informatik an der Humboldt Universität zu Berlin und schloss dieses Studium als Diplom-Informatiker ab. Anschließend folgten Anstellungen als Software-Architekt und -Entwickler bei der beusen Solutions GmbH und als IT-Berater und Softwareentwickler im Bereich Consulting der Oracle Deutschland GmbH. Seit 2004 ist er für die Hitabis GmbH tätig.`}</p>
      </BigBox>
      <BigBox mdxType="BigBox">
        <p><img parentName="p" {...{
            "src": "/people/heiko-thiele-hitabis-berlin.jpg",
            "alt": "Heiko Thiele"
          }}></img></p>
        <h3>{`Heiko Thiele`}</h3>
        <h4>{`Experte IT-Beratung`}</h4>
        <p>{`war nach seinem Studium der Informatik an der technischen Universität Berlin für verschiedene Unternehmen als freiberuflicher und angestellter Softwareentwickler tätig. Von 2001 bis 2004 hatte er den Posten eines Gesellschafters und Geschäftsführers der TechSpring GmbH inne. Er erwarb das Zertifikat »Oracle Certified Professional«. Er ist seit ihrer Gründung im Jahr 2004 für die Hitabis GmbH tätig und ebenso einer der Mitgründer und Geschäftsführer.`}</p>
      </BigBox>
      <BigBox mdxType="BigBox">
        <p><img parentName="p" {...{
            "src": "/people/katrin-thielemann-hitabis-berlin.jpg",
            "alt": "Katrin Thielemann"
          }}></img></p>
        <h3>{`Katrin Thielemann`}</h3>
        <h4>{`Expertin Systemintegration`}</h4>
        <p>{`ist gleichfalls Mitgründerin und Geschäftsführerin der Hitabis GmbH. Nach dem Abschluss ihres Studiums der Wirtschaftsinformatik an der Hochschule für Ökonomie Berlin als Diplom- Wirtschaftsinformatikerin arbeitete sie mehrere Jahre freiberuflich als IT-Beraterin, Softwareentwicklerin und IT-Ausbilderin in der Erwachsenenqualifizierung. Sie unterstützte acht Jahre lang die Oracle Deutschland GmbH im Bereich Consulting, bevor sie 2004 zur Hitabis GmbH wechselte.`}</p>
      </BigBox>
    </Rows>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      